@font-face {
  font-family: 'Rubik';
  src: local('Rubik'), url(./fonts/Rubik-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Rubik';
  src: local('Rubik'), url(./fonts/Rubik-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Black.ttf) format('truetype');
  font-weight: 900;
}
